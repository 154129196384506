/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import React, { useCallback, useContext } from 'react';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { Condition as ConditionBase, IConditionProps as IConditionBaseProps, IFormState } from '@ngt/forms-core';

import * as Dtos from '../../api/dtos';
import IDtoClass from '../../utilities/IDtoClass';

import FormLookupsContext from '../../contexts/form/FormLookupsContext';

import LabelsContext, { IFormLabel } from '../../contexts/form/LabelsContext';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

type OmitCondition<T> = Omit<T, 'condition'>


export interface IConditionParameters<TValues extends object = any> {
    formState?: IFormState<TValues, Dtos.IValidationError>;
    lookups?: Dtos.ILookup[];
    labels?: IFormLabel[];
    parentFieldName?: string | null;
}

/**
 * This interface defines the properties for the Condition component.
 */
export interface IConditionProps<TValues extends Object = any> extends OmitCondition<IConditionBaseProps<TValues, Dtos.IValidationError>> {
    type: IDtoClass<TValues>;
    condition: (params: IConditionParameters<TValues>) => boolean
}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const Condition = <TValues extends Object = any>({
    condition,
    type,
    children,
    ...rest
}: IConditionProps<TValues>) => {
    const labelsContext = useContext(LabelsContext);
    const lookupsContext = useContext(FormLookupsContext);


    const formCondition = useCallback((formState: IFormState<TValues, Dtos.IValidationError>, parentFieldName?: string | null) => {
        return condition({ formState, labels: labelsContext, lookups: lookupsContext, parentFieldName });
    }, [condition, lookupsContext, labelsContext])

    return (
        <ConditionBase
            {...rest}
            condition={formCondition}
        >
            {children}
        </ConditionBase>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default Condition;