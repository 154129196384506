import { Grid, GridProps, Theme, GridSize, useTheme, Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { FormErrorBoundary, FormErrorBoundaryMode, IFieldProps as IBaseFieldProps, InputComponentType, OmitInputRender } from '@ngt/forms-core';
import { isString } from 'lodash';
import React, { ComponentProps, ComponentType, ReactNode, useContext, useMemo } from 'react';
import GroupedFieldsContext from '../../../../contexts/form/GroupedFieldsContext';
import FormOptionsContext, { FieldErrorOption } from '../../../../contexts/form/FormOptionsContext';
import LabelContext from '../../../../contexts/form/LabelContext';
import FieldProvider from '../../FieldProvider';
import FormErrorHandler from '../../FormErrorHandler';
import FormLabel, { IFormLabelProps } from '../../FormLabel';
import Input, { IInputProps } from '../../Input';
import LookupFilter, { ILookupFilterProps } from '../../LookupFilter';
import GroupedErrorDisplay, { IGroupedErrorDisplayProps } from './GroupedErrorDisplay';
import GroupedErrorFlag, { ShowErrorFlagFunction } from './GroupedErrorFlag';
import { makeStyles } from '../../../../utilities/makeStyles';
import classnames from 'classnames';

type OmitProps<T> = Omit<T, 'name' | 'label'>;

interface IFieldOnlyProps<TValue = any> extends OmitProps<IBaseFieldProps<TValue>> {
    errorMode?: FormErrorBoundaryMode;
}

export interface IGroupedFieldProps<
    TComponent extends InputComponentType = any,
    TValue = any,
    TForm extends object = any
    > extends IInputProps<TComponent, TValue> {
    name: string;
    label?: ReactNode;
    LabelProps?: IGroupedFieldLabelProps;
    FieldProps?: IFieldOnlyProps<TValue>;
    LookupFilterProps?: ILookupFilterProps<TForm>;
    GroupStyleProps?: IGroupedFieldStyleProps;
    ErrorDisplayProps?: IGroupedFieldErrorDisplayProps;
    WrappingComponent?: ComponentType<{ children?: ReactNode }>;
}



export interface IGroupedFieldLabelProps extends Omit<IFormLabelProps, 'label'> {
    hideLabel?: boolean;
}

export interface IGroupedFieldErrorDisplayProps extends Omit<IGroupedErrorDisplayProps, 'fieldErrorOption'> {
    showErrorFlag?: ShowErrorFlagFunction
}

export interface IGroupedFieldStyleProps {
    fieldColor?: 'Auto' | 'Inherit' | 'None';
    paddingTop?: boolean | number;
    paddingBottom?: boolean | number;
    isGrouped?: boolean;
    hideLabel?: boolean;
    labelColumnClassName?: string;
    inputColumnClassName?: string;
    labelColumnSx?: SxProps<Theme>;
    inputColumnSx?: SxProps<Theme>;
    labelColumn?: IGroupedFieldStyleColumns;
    inputColumn?: IGroupedFieldStyleColumns;
}

export interface IGroupedFieldStyleColumns {
    xs?: GridProps['xs'];
    sm?: GridProps['sm'];
    md?: GridProps['md'];
    lg?: GridProps['lg'];
    xl?: GridProps['xl'];
}

interface IColumnSizes {
    labelColumn: {
        xs: boolean | GridSize;
        sm: boolean | GridSize;
        md: boolean | GridSize;
        lg: boolean | GridSize;
        xl: boolean | GridSize;
    },
    inputColumn: {
        xs: boolean | GridSize;
        sm: boolean | GridSize;
        md: boolean | GridSize;
        lg: boolean | GridSize;
        xl: boolean | GridSize;
    }
}

const useStyles = makeStyles<{ props: IGroupedFieldStyleProps & { columnSizes: IColumnSizes } }>()((theme: Theme, {props}) => ({
    container: {
        borderBottom:props.fieldColor === 'Auto' || !props.fieldColor ? `1px solid ${theme.palette.grey[300]}` : undefined,
        borderTop:props.fieldColor === 'Auto' || !props.fieldColor ? `1px solid ${theme.palette.grey[300]}` : undefined,
        marginTop:props.fieldColor === 'Auto' || !props.fieldColor ? -1 : undefined,

        '&:nth-of-type(even)': {
            background:props.fieldColor === 'Auto' || !props.fieldColor ? theme.palette.grey[100] : undefined
        },

        '&:nth-of-type(odd)': {
            background:props.fieldColor === 'Auto' || !props.fieldColor ? theme.palette.common.white : undefined
        },

        '&:first-of-type $grid': {
            paddingTop:props.paddingTop === undefined && props.isGrouped ?
                theme.spacing(3) :
                undefined
        },

        '&:last-of-type $grid': {
            paddingBottom:props.paddingBottom === undefined && props.isGrouped ?
                theme.spacing(3) :
                undefined
        }
    },
    grid: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop:props.paddingTop === false ?
            theme.spacing(0) :
            props.paddingTop === true ?
                theme.spacing(2) :
                props.paddingTop === undefined && props.isGrouped ?
                    theme.spacing(1) :
                    props.paddingTop === undefined ?
                        theme.spacing(2) :
                        theme.spacing(props.paddingTop),
        paddingBottom:props.paddingBottom === false ?
            theme.spacing(0) :
            props.paddingBottom === true ?
                theme.spacing(2) :
                props.paddingBottom === undefined && props.isGrouped ?
                    theme.spacing(1) :
                    props.paddingBottom === undefined ?
                        theme.spacing(2) :
                        theme.spacing(props.paddingBottom),

        //paddingTop:props.paddingTop === false ?
        //    theme.spacing(0) :
        //    props.paddingTop === true ?
        //        theme.spacing(3) :
        //        props.paddingTop === undefined ?
        //            theme.spacing(3) :
        //            theme.spacing(props.paddingTop),

        //paddingBottom:props.paddingBottom === false ?
        //    theme.spacing(0) :
        //    props.paddingBottom === true ?
        //        theme.spacing(3) :
        //        props.paddingBottom === undefined ?
        //            theme.spacing(3) :
        //            theme.spacing(props.paddingBottom),
    },
    field: {
        [theme.breakpoints.only('xl')]: {
            paddingTop: (props.columnSizes.inputColumn.xl === 12 || props.columnSizes.labelColumn.xl === 12) && props.hideLabel !== true ? theme.spacing(1.5) : ''
        },
        [theme.breakpoints.only('lg')]: {
            paddingTop: (props.columnSizes.inputColumn.lg === 12 || props.columnSizes.labelColumn.lg === 12) && props.hideLabel !== true ? theme.spacing(1.5) : ''
        },
        [theme.breakpoints.only('md')]: {
            paddingTop: (props.columnSizes.inputColumn.md === 12 || props.columnSizes.labelColumn.md === 12) && props.hideLabel !== true ? theme.spacing(1.5) : ''
        },
        [theme.breakpoints.only('sm')]: {
            paddingTop: (props.columnSizes.inputColumn.sm === 12 || props.columnSizes.labelColumn.sm === 12) && props.hideLabel !== true ? theme.spacing(1.5) : ''
        },
        [theme.breakpoints.only('xs')]: {
            paddingTop: (props.columnSizes.inputColumn.xs === 12 || props.columnSizes.labelColumn.xs === 12) && props.hideLabel !== true ? theme.spacing(1.5) : ''
        }
    },
    inputContainer: {
        display: 'flex'
    },
    input: {
        minWidth: 0,
        maxWidth: 'calc(100% - 32px)',
        flex: '1 1 auto'
    },
    label: {
        paddingTop: 4
    }
}));

const getLabelColumnSize = (labelColumnSize: boolean | GridSize | undefined, inputColumnSize: boolean | GridSize | undefined, defaultSize: boolean | GridSize) => {
    if (labelColumnSize) {
        return labelColumnSize;
    }

    return inputColumnSize ? inputColumnSize === 'auto' || inputColumnSize === 12 ? 'auto' : 12 - (inputColumnSize as number) : defaultSize;
}

const getInputColumnSize = (labelColumnSize: boolean | GridSize | undefined, inputColumnSize: boolean | GridSize | undefined, defaultSize: boolean | GridSize) => {
    if (inputColumnSize) {
        return inputColumnSize;
    }

    return labelColumnSize ? labelColumnSize === 'auto' || labelColumnSize === 12 ? 'auto' : 12 - (labelColumnSize as number) : defaultSize;
}

const GroupedField = <
    TComponent extends InputComponentType = any,
    TValue = any
>({
    name,
    label,
    fieldErrorOption,
    LabelProps: { hideLabel, ...labelProps } = {},
    FieldProps: { errorMode = FormErrorBoundaryMode.Contain, ...fieldProps } = { errorMode: FormErrorBoundaryMode.Contain },
    LookupFilterProps,
    GroupStyleProps,
    ErrorDisplayProps,
    WrappingComponent,
    ...props
}: IGroupedFieldProps<TComponent, TValue> & Partial<OmitInputRender<ComponentProps<TComponent>>>) => {
    const columnSizes = useMemo(() => {
        return {
            labelColumn: {
                xs: getLabelColumnSize(GroupStyleProps?.labelColumn?.xs, GroupStyleProps?.inputColumn?.xs, 12),
                sm: getLabelColumnSize(GroupStyleProps?.labelColumn?.sm, GroupStyleProps?.inputColumn?.sm, 12),
                md: getLabelColumnSize(GroupStyleProps?.labelColumn?.md, GroupStyleProps?.inputColumn?.md, 8),
                lg: getLabelColumnSize(GroupStyleProps?.labelColumn?.lg, GroupStyleProps?.inputColumn?.lg, 9),
                xl: getLabelColumnSize(GroupStyleProps?.labelColumn?.xl, GroupStyleProps?.inputColumn?.xl, 9)
            },
            inputColumn: {
                xs: getInputColumnSize(GroupStyleProps?.labelColumn?.xs, GroupStyleProps?.inputColumn?.xs, 12),
                sm: getInputColumnSize(GroupStyleProps?.labelColumn?.sm, GroupStyleProps?.inputColumn?.sm, 12),
                md: getInputColumnSize(GroupStyleProps?.labelColumn?.md, GroupStyleProps?.inputColumn?.md, 4),
                lg: getInputColumnSize(GroupStyleProps?.labelColumn?.lg, GroupStyleProps?.inputColumn?.lg, 3),
                xl: getInputColumnSize(GroupStyleProps?.labelColumn?.xl, GroupStyleProps?.inputColumn?.xl, 3)
            }
        }
    }, [GroupStyleProps?.labelColumn?.xs, GroupStyleProps?.labelColumn?.sm, GroupStyleProps?.labelColumn?.md, GroupStyleProps?.labelColumn?.lg, GroupStyleProps?.labelColumn?.xl,
        GroupStyleProps?.inputColumn?.xs, GroupStyleProps?.inputColumn?.sm, GroupStyleProps?.inputColumn?.md, GroupStyleProps?.inputColumn?.lg, GroupStyleProps?.inputColumn?.xl])

    const isGrouped = useContext(GroupedFieldsContext);

    const theme = useTheme();

    const styles = useMemo(() => {
        const fieldColor = isGrouped ? 'Inherit' : GroupStyleProps?.fieldColor;

        return {
            container: {
                borderBottom: fieldColor === 'Auto' || !fieldColor ? `1px solid ${theme.palette.grey[300]}` : undefined,
                borderTop: fieldColor === 'Auto' || !fieldColor ? `1px solid ${theme.palette.grey[300]}` : undefined,
                marginTop: fieldColor === 'Auto' || !fieldColor ? -0.5 : undefined,

                '&:nth-of-type(even)': {
                    background: fieldColor === 'Auto' || !fieldColor ? theme.palette.grey[100] : undefined
                },

                '&:nth-of-type(odd)': {
                    background: fieldColor === 'Auto' || !fieldColor ? theme.palette.common.white : undefined
                },

                '&:first-of-type $grid': {
                    paddingTop: GroupStyleProps?.paddingTop === undefined && isGrouped ?
                        theme.spacing(3) :
                        undefined
                },

                '&:last-of-type $grid': {
                    paddingBottom: GroupStyleProps?.paddingBottom === undefined && isGrouped ?
                        theme.spacing(3) :
                        undefined
                }
            },
            grid: {
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
                paddingTop: GroupStyleProps?.paddingTop === false ?
                    theme.spacing(0) :
                    GroupStyleProps?.paddingTop === true ?
                        theme.spacing(2) :
                        GroupStyleProps?.paddingTop === undefined && isGrouped ?
                            theme.spacing(1) :
                            GroupStyleProps?.paddingTop === undefined ?
                                theme.spacing(2) :
                                theme.spacing(GroupStyleProps?.paddingTop ?? 0),
                paddingBottom: GroupStyleProps?.paddingBottom === false ?
                    theme.spacing(0) :
                    GroupStyleProps?.paddingBottom === true ?
                        theme.spacing(2) :
                        GroupStyleProps?.paddingBottom === undefined && isGrouped ?
                            theme.spacing(1) :
                            GroupStyleProps?.paddingBottom === undefined ?
                                theme.spacing(2) :
                                theme.spacing(GroupStyleProps?.paddingBottom ?? 0),
            },
            field: {
                [theme.breakpoints.only('xl')]: {
                    paddingTop: (columnSizes.inputColumn.xl === 12 || columnSizes.labelColumn.xl === 12) && hideLabel !== true ? theme.spacing(1.5) : ''
                },
                [theme.breakpoints.only('lg')]: {
                    paddingTop: (columnSizes.inputColumn.lg === 12 || columnSizes.labelColumn.lg === 12) && hideLabel !== true ? theme.spacing(1.5) : ''
                },
                [theme.breakpoints.only('md')]: {
                    paddingTop: (columnSizes.inputColumn.md === 12 || columnSizes.labelColumn.md === 12) && hideLabel !== true ? theme.spacing(1.5) : ''
                },
                [theme.breakpoints.only('sm')]: {
                    paddingTop: (columnSizes.inputColumn.sm === 12 || columnSizes.labelColumn.sm === 12) && hideLabel !== true ? theme.spacing(1.5) : ''
                },
                [theme.breakpoints.only('xs')]: {
                    paddingTop: (columnSizes.inputColumn.xs === 12 || columnSizes.labelColumn.xs === 12) && hideLabel !== true ? theme.spacing(1.5) : ''
                }
            },
            inputContainer: {
                display: 'flex'
            },
            input: {
                minWidth: 0,
                maxWidth: 'calc(100% - 32px)',
                flex: '1 1 auto'
            },
            label: {
                paddingTop: '4px'
            }
        }
    }, [theme, columnSizes, props, isGrouped, GroupStyleProps]);

    const formOptions = useContext(FormOptionsContext);

    const fieldErrorOptionToUse = fieldErrorOption ?? formOptions.fieldErrors;

    const { classes } = useStyles({ props: { ...GroupStyleProps, fieldColor: isGrouped ? 'Inherit' : GroupStyleProps?.fieldColor, columnSizes } });

    let internal = (
        <Input name={name} {...props} label={null} fieldErrorOption={FieldErrorOption.AutoHideErrorMessages} />
    );

    if (fieldErrorOptionToUse !== FieldErrorOption.AlwaysHideErrors) {
        internal = (
            <Box
                sx={styles.inputContainer}
            >
                <Box
                    sx={styles.input}
                >
                    {internal}
                </Box>
                <GroupedErrorFlag showErrorFlag={ErrorDisplayProps?.showErrorFlag} showErrorFlagExt={ErrorDisplayProps?.showError} />
            </Box>
        )
    }

    if(LookupFilterProps)
    {
        internal = <LookupFilter {...LookupFilterProps}>
            {internal}
        </LookupFilter>;
    }

    if (WrappingComponent) {
        internal = <WrappingComponent>
            {internal}
        </WrappingComponent>
    }

    return (
        <Box
            sx={styles.container}
        >
            <FormErrorHandler
                errorMode={errorMode}
            >
                <FieldProvider name={name} {...fieldProps}>
                    <Grid
                        container
                        sx={styles.grid}
                    >
                        {
                            hideLabel !== true && (
                                <Grid
                                    item
                                    className={classnames(classes.label, GroupStyleProps?.labelColumnClassName)}
                                    xs={columnSizes.labelColumn.xs}
                                    sm={columnSizes.labelColumn.sm}
                                    md={columnSizes.labelColumn.md}
                                    lg={columnSizes.labelColumn.lg}
                                    xl={columnSizes.labelColumn.xl}
                                    sx={{ ...GroupStyleProps?.labelColumnSx, ...styles.label }}
                                >
                                    {
                                        label === undefined || isString(label) ?
                                            <FormLabel {...labelProps} label={label} /> :
                                            label

                                    }
                                </Grid>
                            )
                        }
                        <Grid
                            item
                            xs={columnSizes.inputColumn.xs}
                            sm={columnSizes.inputColumn.sm}
                            md={columnSizes.inputColumn.md}
                            lg={columnSizes.inputColumn.lg}
                            xl={columnSizes.inputColumn.xl}
                            className={classnames(classes.field, GroupStyleProps?.inputColumnClassName)}
                            sx={{ ...GroupStyleProps?.inputColumnSx, ...styles.field }}
                        >
                            {internal}
                        </Grid>
                    </Grid>
                </FieldProvider>
                <GroupedErrorDisplay
                    {...ErrorDisplayProps}
                    fieldErrorOption={fieldErrorOption}
                />
            </FormErrorHandler>
        </Box>
    )
};

export default GroupedField;