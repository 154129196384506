/* Options:
Date: 2021-08-06 08:35:37
Version: 5.110
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://localhost:44379

//GlobalNamespace: 
//MakePropertiesOptional: False
AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
IncludeTypes: TrialsRequestContext,NewtonGreen.ServiceStack.Trials.Interfaces/*,NewtonGreen.ServiceStack.Trials.Enumerations/*,__Trials.MasterGroup/*,__Trials.CoordinatingGroup/*,__Trials.Country/*,__Trials.Institution/*,__Trials.Patient/*
//ExcludeTypes: 
DefaultImports: IDataModel:@ngt/forms,IReturn:@ngt/forms,IGet:@ngt/forms,IPost:@ngt/forms,IConfigurationModel:@ngt/forms,IConfigurationGetSingleById:@ngt/forms,IConfigurationGetCollection:@ngt/forms,IConfigurationGetSingleByCode:@ngt/forms,IHasCode:@ngt/forms,IDataGetSingleById:@ngt/forms,IDataGetCollection:@ngt/forms,ResponseStatus:@ngt/forms,MasterGroup:./masterGroup,CoordinatingGroup:./coordinatingGroup,Country:./country,Institution:./institution,Patient:./patient,ValidationResult:./ValidationResult
*/

import { IDataModel } from "@ngt/forms";
import { IReturn } from "@ngt/forms";
import { IGet } from "@ngt/forms";
import { IPost } from "@ngt/forms";
import { IConfigurationModel } from "@ngt/forms";
import { IConfigurationGetSingleById } from "@ngt/forms";
import { IConfigurationGetCollection } from "@ngt/forms";
import { IConfigurationGetSingleByCode } from "@ngt/forms";
import { IHasCode } from "@ngt/forms";
import { IDataGetSingleById } from "@ngt/forms";
import { IDataGetCollection } from "@ngt/forms";
import { ResponseStatus } from "@ngt/forms";
import { MasterGroup } from "./masterGroup";
import { CoordinatingGroup } from "./coordinatingGroup";
import { Country } from "./country";
import { Institution } from "./institution";
import { Patient } from "./patient";
import { ValidationResult } from "./ValidationResult";

export interface IPatientGetSingleByStudyNumber
{
    studyNumber: string;
}

export interface IMasterGroup extends IDataModel
{
    name: string;
    code: string;
}

export interface ICoordinatingGroup extends IDataModel, IHasMasterGroupId
{
    name: string;
    code: string;
}

export interface IHasMasterGroupId
{
    masterGroupId?: number;
}

export interface ICountry extends IDataModel
{
    name: string;
    code: string;
}

export interface IInstitution extends IDataModel, IHasMasterGroupId, IHasCoordinatingGroupId, IHasCountryId
{
    name: string;
    code: string;
    enabled?: boolean;
    activatedDate?: string;
}

export interface IHasCoordinatingGroupId
{
    coordinatingGroupId?: number;
}

export interface IHasCountryId
{
    countryId?: number;
}

export interface IPatient extends IDataModel, IHasInstitutionId
{
    studyNumber: string;
}

export interface IHasInstitutionId
{
    institutionId?: number;
}

export interface IHasPatientId
{
    patientId?: number;
}

// @DataContract
export class MasterGroupSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved master group.
    */
    // @DataMember(Order=2)
    public data: MasterGroup;

    public constructor(init?: Partial<MasterGroupSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MasterGroupCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved master groups.
    */
    // @DataMember(Order=2)
    public data: MasterGroup[];

    public constructor(init?: Partial<MasterGroupCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MasterGroupValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<MasterGroupValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CoordinatingGroupSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved coordinating group.
    */
    // @DataMember(Order=2)
    public data: CoordinatingGroup;

    public constructor(init?: Partial<CoordinatingGroupSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CoordinatingGroupCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved coordinating groups.
    */
    // @DataMember(Order=2)
    public data: CoordinatingGroup[];

    public constructor(init?: Partial<CoordinatingGroupCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CoordinatingGroupValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<CoordinatingGroupValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CountrySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved country.
    */
    // @DataMember(Order=2)
    public data: Country;

    public constructor(init?: Partial<CountrySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CountryCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved countrys.
    */
    // @DataMember(Order=2)
    public data: Country[];

    public constructor(init?: Partial<CountryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CountryValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<CountryValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InstitutionSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved institution.
    */
    // @DataMember(Order=2)
    public data: Institution;

    public constructor(init?: Partial<InstitutionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InstitutionCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved institutions.
    */
    // @DataMember(Order=2)
    public data: Institution[];

    public constructor(init?: Partial<InstitutionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InstitutionValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<InstitutionValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved patient.
    */
    // @DataMember(Order=2)
    public data: Patient;

    public constructor(init?: Partial<PatientSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved patients.
    */
    // @DataMember(Order=2)
    public data: Patient[];

    public constructor(init?: Partial<PatientCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<PatientValidationResponse>) { (Object as any).assign(this, init); }
}

/**
* Master Group
*/
// @Route("/medical-review/master-group/single/id/{Id}", "GET")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=500)
// @DataContract
export class MasterGroupGetSingleById implements IReturn<MasterGroupSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the master group to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MasterGroupGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new MasterGroupSingleResponse(); }
    public getTypeName() { return 'MasterGroupGetSingleById'; }
}

/**
* Master Group
*/
// @Route("/medical-review/master-group/collection", "GET")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class MasterGroupGetCollection implements IReturn<MasterGroupCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<MasterGroupGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new MasterGroupCollectionResponse(); }
    public getTypeName() { return 'MasterGroupGetCollection'; }
}

/**
* Master Group
*/
// @Route("/medical-review/master-group/create", "POST")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=500)
// @DataContract
export class MasterGroupPostCreate implements IReturn<MasterGroupSingleResponse>
{
    /**
    * The metadata to use when creating the master group.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the master group.
    */
    // @DataMember(Order=2)
    public data: MasterGroup;

    public constructor(init?: Partial<MasterGroupPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new MasterGroupSingleResponse(); }
    public getTypeName() { return 'MasterGroupPostCreate'; }
}

/**
* Master Group
*/
// @Route("/medical-review/master-group/save", "POST")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupSingleResponse)", StatusCode=500)
// @DataContract
export class MasterGroupPostSave implements IReturn<MasterGroupSingleResponse>
{
    /**
    * The metadata to use when creating the master group.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The master group to save.
    */
    // @DataMember(Order=2)
    public data: MasterGroup;

    public constructor(init?: Partial<MasterGroupPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new MasterGroupSingleResponse(); }
    public getTypeName() { return 'MasterGroupPostSave'; }
}

/**
* Master Group
*/
// @Route("/medical-review/master-group/validate", "POST")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.MasterGroup.MasterGroupValidationResponse)", StatusCode=500)
// @DataContract
export class MasterGroupPostValidate implements IReturn<MasterGroupValidationResponse>
{
    /**
    * The metadata to use when validating the master group.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The master group to validate.
    */
    // @DataMember(Order=2)
    public data: MasterGroup;

    public constructor(init?: Partial<MasterGroupPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new MasterGroupValidationResponse(); }
    public getTypeName() { return 'MasterGroupPostValidate'; }
}

/**
* Coordinating Group
*/
// @Route("/medical-review/coordinating-group/single/id/{Id}", "GET")
// @Api(Description="Coordinating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=500)
// @DataContract
export class CoordinatingGroupGetSingleById implements IReturn<CoordinatingGroupSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the coordinating group to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CoordinatingGroupGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new CoordinatingGroupSingleResponse(); }
    public getTypeName() { return 'CoordinatingGroupGetSingleById'; }
}

/**
* Coordinating Group
*/
// @Route("/medical-review/coordinating-group/collection", "GET")
// @Api(Description="Coordinating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class CoordinatingGroupGetCollection implements IReturn<CoordinatingGroupCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<CoordinatingGroupGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new CoordinatingGroupCollectionResponse(); }
    public getTypeName() { return 'CoordinatingGroupGetCollection'; }
}

/**
* Coordinating Group
*/
// @Route("/medical-review/coordinating-group/create", "POST")
// @Api(Description="Coordinating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=500)
// @DataContract
export class CoordinatingGroupPostCreate implements IReturn<CoordinatingGroupSingleResponse>
{
    /**
    * The metadata to use when creating the coordinating group.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the coordinating group.
    */
    // @DataMember(Order=2)
    public data: CoordinatingGroup;

    public constructor(init?: Partial<CoordinatingGroupPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new CoordinatingGroupSingleResponse(); }
    public getTypeName() { return 'CoordinatingGroupPostCreate'; }
}

/**
* Coordinating Group
*/
// @Route("/medical-review/coordinating-group/save", "POST")
// @Api(Description="Coordinating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupSingleResponse)", StatusCode=500)
// @DataContract
export class CoordinatingGroupPostSave implements IReturn<CoordinatingGroupSingleResponse>
{
    /**
    * The metadata to use when creating the coordinating group.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The coordinating group to save.
    */
    // @DataMember(Order=2)
    public data: CoordinatingGroup;

    public constructor(init?: Partial<CoordinatingGroupPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new CoordinatingGroupSingleResponse(); }
    public getTypeName() { return 'CoordinatingGroupPostSave'; }
}

/**
* Coordinating Group
*/
// @Route("/medical-review/coordinating-group/validate", "POST")
// @Api(Description="Coordinating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.CoordinatingGroup.CoordinatingGroupValidationResponse)", StatusCode=500)
// @DataContract
export class CoordinatingGroupPostValidate implements IReturn<CoordinatingGroupValidationResponse>
{
    /**
    * The metadata to use when validating the coordinating group.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The coordinating group to validate.
    */
    // @DataMember(Order=2)
    public data: CoordinatingGroup;

    public constructor(init?: Partial<CoordinatingGroupPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new CoordinatingGroupValidationResponse(); }
    public getTypeName() { return 'CoordinatingGroupPostValidate'; }
}

/**
* Country
*/
// @Route("/medical-review/country/single/id/{Id}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=500)
// @DataContract
export class CountryGetSingleById implements IReturn<CountrySingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the country to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CountryGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new CountrySingleResponse(); }
    public getTypeName() { return 'CountryGetSingleById'; }
}

/**
* Country
*/
// @Route("/medical-review/country/collection", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Country.CountryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Country.CountryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Country.CountryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Country.CountryCollectionResponse)", StatusCode=500)
// @DataContract
export class CountryGetCollection implements IReturn<CountryCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<CountryGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new CountryCollectionResponse(); }
    public getTypeName() { return 'CountryGetCollection'; }
}

/**
* Country
*/
// @Route("/medical-review/country/create", "POST")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=500)
// @DataContract
export class CountryPostCreate implements IReturn<CountrySingleResponse>
{
    /**
    * The metadata to use when creating the country.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the country.
    */
    // @DataMember(Order=2)
    public data: Country;

    public constructor(init?: Partial<CountryPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new CountrySingleResponse(); }
    public getTypeName() { return 'CountryPostCreate'; }
}

/**
* Country
*/
// @Route("/medical-review/country/save", "POST")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Country.CountrySingleResponse)", StatusCode=500)
// @DataContract
export class CountryPostSave implements IReturn<CountrySingleResponse>
{
    /**
    * The metadata to use when creating the country.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The country to save.
    */
    // @DataMember(Order=2)
    public data: Country;

    public constructor(init?: Partial<CountryPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new CountrySingleResponse(); }
    public getTypeName() { return 'CountryPostSave'; }
}

/**
* Country
*/
// @Route("/medical-review/country/validate", "POST")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Country.CountryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Country.CountryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Country.CountryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Country.CountryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Country.CountryValidationResponse)", StatusCode=500)
// @DataContract
export class CountryPostValidate implements IReturn<CountryValidationResponse>
{
    /**
    * The metadata to use when validating the country.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The country to validate.
    */
    // @DataMember(Order=2)
    public data: Country;

    public constructor(init?: Partial<CountryPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new CountryValidationResponse(); }
    public getTypeName() { return 'CountryPostValidate'; }
}

/**
* Institution
*/
// @Route("/medical-review/institution/single/id/{Id}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetSingleById implements IReturn<InstitutionSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the institution to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<InstitutionGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new InstitutionSingleResponse(); }
    public getTypeName() { return 'InstitutionGetSingleById'; }
}

/**
* Institution
*/
// @Route("/medical-review/institution/collection", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollection implements IReturn<InstitutionCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<InstitutionGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new InstitutionCollectionResponse(); }
    public getTypeName() { return 'InstitutionGetCollection'; }
}

/**
* Institution
*/
// @Route("/medical-review/institution/create", "POST")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=500)
// @DataContract
export class InstitutionPostCreate implements IReturn<InstitutionSingleResponse>
{
    /**
    * The metadata to use when creating the institution.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the institution.
    */
    // @DataMember(Order=2)
    public data: Institution;

    public constructor(init?: Partial<InstitutionPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new InstitutionSingleResponse(); }
    public getTypeName() { return 'InstitutionPostCreate'; }
}

/**
* Institution
*/
// @Route("/medical-review/institution/save", "POST")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Institution.InstitutionSingleResponse)", StatusCode=500)
// @DataContract
export class InstitutionPostSave implements IReturn<InstitutionSingleResponse>
{
    /**
    * The metadata to use when creating the institution.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The institution to save.
    */
    // @DataMember(Order=2)
    public data: Institution;

    public constructor(init?: Partial<InstitutionPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new InstitutionSingleResponse(); }
    public getTypeName() { return 'InstitutionPostSave'; }
}

/**
* Institution
*/
// @Route("/medical-review/institution/validate", "POST")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Institution.InstitutionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Institution.InstitutionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Institution.InstitutionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Institution.InstitutionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Institution.InstitutionValidationResponse)", StatusCode=500)
// @DataContract
export class InstitutionPostValidate implements IReturn<InstitutionValidationResponse>
{
    /**
    * The metadata to use when validating the institution.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The institution to validate.
    */
    // @DataMember(Order=2)
    public data: Institution;

    public constructor(init?: Partial<InstitutionPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new InstitutionValidationResponse(); }
    public getTypeName() { return 'InstitutionPostValidate'; }
}

/**
* Patient - MedicalReviewPatient
*/
// @Route("/medical-review/patient/single/id/{Id}", "GET")
// @Api(Description="Patient - MedicalReviewPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientGetSingleById implements IReturn<PatientSingleResponse>, IDataGetSingleById
{
    /**
    * The ID of the patient to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PatientGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new PatientSingleResponse(); }
    public getTypeName() { return 'PatientGetSingleById'; }
}

/**
* Patient - MedicalReviewPatient
*/
// @Route("/medical-review/patient/collection", "GET")
// @Api(Description="Patient - MedicalReviewPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollection implements IReturn<PatientCollectionResponse>, IDataGetCollection
{
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<PatientGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new PatientCollectionResponse(); }
    public getTypeName() { return 'PatientGetCollection'; }
}

/**
* Patient - MedicalReviewPatient
*/
// @Route("/medical-review/patient/create", "POST")
// @Api(Description="Patient - MedicalReviewPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientPostCreate implements IReturn<PatientSingleResponse>
{
    /**
    * The metadata to use when creating the patient.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the patient.
    */
    // @DataMember(Order=2)
    public data: Patient;

    public constructor(init?: Partial<PatientPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new PatientSingleResponse(); }
    public getTypeName() { return 'PatientPostCreate'; }
}

/**
* Patient - MedicalReviewPatient
*/
// @Route("/medical-review/patient/save", "POST")
// @Api(Description="Patient - MedicalReviewPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientPostSave implements IReturn<PatientSingleResponse>
{
    /**
    * The metadata to use when creating the patient.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The patient to save.
    */
    // @DataMember(Order=2)
    public data: Patient;

    public constructor(init?: Partial<PatientPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new PatientSingleResponse(); }
    public getTypeName() { return 'PatientPostSave'; }
}

/**
* Patient - MedicalReviewPatient
*/
// @Route("/medical-review/patient/validate", "POST")
// @Api(Description="Patient - MedicalReviewPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Patient.PatientValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientValidationResponse)", StatusCode=500)
// @DataContract
export class PatientPostValidate implements IReturn<PatientValidationResponse>
{
    /**
    * The metadata to use when validating the patient.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The patient to validate.
    */
    // @DataMember(Order=2)
    public data: Patient;

    public constructor(init?: Partial<PatientPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new PatientValidationResponse(); }
    public getTypeName() { return 'PatientPostValidate'; }
}

/**
* Patient - MedicalReviewPatient
*/
// @Route("/medical-review/patient/single/study-number/{StudyNumber}", "GET")
// @Api(Description="Patient - MedicalReviewPatient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Trials.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientGetSingleByStudyNumber implements IReturn<PatientSingleResponse>, IPatientGetSingleByStudyNumber
{
    /**
    * The Study Number of the patient to retrieve.
    */
    // @DataMember(Order=1)
    public studyNumber: string;

    public constructor(init?: Partial<PatientGetSingleByStudyNumber>) { (Object as any).assign(this, init); }
    public createResponse() { return new PatientSingleResponse(); }
    public getTypeName() { return 'PatientGetSingleByStudyNumber'; }
}

